import React from 'react';
//import { ReactComponent as Music } from '../assets/svg/music.svg';
// @ts-ignore
//import * as Marquee from 'react-double-marquee';
import { VideoItem, selectIsLogged } from '../store/screenSlice';
import { useSelector } from 'react-redux';

export default function VideoDetails({ item }: { item: VideoItem }) {

  const isLogged = useSelector(selectIsLogged);

  return (
    <div 
      style={{
        display: 'flex',
        alignContent: 'flex-end',
        flexDirection: 'column-reverse',
      }}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div
          style={{
            fontSize: '1.0em',
            fontWeight: 'bold',
          }}
        >
          <h3>{item.name}</h3>
        </div>
        <div style={{ marginTop: 10, fontSize: '0.8em', textAlign: 'justify'}}>
          {item.description}{' '} 
          <div>
            {item.hashtags.map((name) => (
              <span key={name}>
                <strong>#{name}</strong>{' '}
              </span>
            ))}
          </div>
          {!isLogged ? <div className="cancelDiv" onClick={() => window.location.href='https://supergameroll.zed.com/bgd/gp/unsubscribe/'}>সদস্যতা বাতিল করুন</div> : null}
        </div>
      </div>
    </div>
  );
}
